<template>
  <div class="home">
    <BlogPost :post="welcomeScreen"/>
    <BlogPost :post="post" v-for="(post,index) in firstTwoBlogs" :key="index"/>
    <div class="blog-card-wrap">
      <div class="container">
        <h3>查看更多的博客</h3>
        <div class="blog-cards">
          <BlogCard :post="post" v-for="(post, index) in blogCards" :key="index"/>
        </div>
      </div>
    </div>
    <div v-show="$store.state.showLogin" class="updates">
      <div class="container">
        <h2>如果不想错过任何的内容. 今天就可以免费注册你的账号!</h2>
        <router-link class="router-button" :to="{ name: 'Login' }" >
          登录/注册 <Arrow class="arrow arrow-ligth"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BlogPost from "../components/BlogPost"
import BlogCard from "../components/BlogCard"
import Arrow from "../assets/Icons/arrow-right-light.svg"

export default {
  name: "Home",
  components: {
    BlogPost,
    BlogCard,
    Arrow
  },
    data() {
      return {
        welcomeScreen: {
          title: "欢迎!",
          blogPost:
            "欢迎来到链智科技，这里将为您提供一手的区块链科技和人工智能资讯!",
          welcomeScreen: true,
          photo: "coding",
        },
      };
    },
    computed:{
      firstTwoBlogs(){
        return this.$store.getters.getFirstTwoBlogs;
      },
      blogCards(){
        return this.$store.getters.getBlogCards;
      }
    },
    beforeRouteEnter(to,from,next){
      console.log(to)
      console.log(from)
      console.log('router enter in APP')
      next()
    },
};
</script>

<style lang="scss" scoped>
.blog-card-wrap {
  h3 {
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 32px;
  }
}
.updates {
  .container {
    padding: 100px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 800px) {
      padding: 125px 25px;
      flex-direction: row;
    }
    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
    h2 {
      font-weight: 300;
      font-size: 32px;
      max-width: 425px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      @media (min-width: 800px) {
        text-align: initial;
        font-size: 40px;
      }
    }
  }
}
</style>
